import React from "react";
import PropTypes from "prop-types";
import { FormBuilder, Components } from "@formio/react";
import components from "./Custom";

Components.setComponents(components);

class CustomFormBuilder extends React.Component {
  // componentDidMount() {
  //   console.log('Demographic called=================')
  // }
  // componentWillMount(){
  //       console.log('Demographic called componentWillMount=================')

  // }

  displyPremium = (comp) => {
    var isPremium = this.props.is_premium_facility;
    const customComponents = this.props.custom_components;
    var compKey = comp.key.match(/^[A-Za-z]+/)[0];
    if (!isPremium && customComponents.includes(compKey)) {
      swal(
        {
          html: false,
          title: "Premium Subscription Required",
          text: "To use our Premium Widgets, your practice needs to have a Premium subscription.",
          showCancelButton: false,
          confirmButtonColor: "#1ab394",
          confirmButtonText: "OK",
          // cancelButtonText: "Cancel",
          closeOnConfirm: true,
          // closeOnCancel: true,
          allowOutsideClick: false,
          icon: "error",
        },
        function (isConfirm) {
          if (isConfirm) {
            $("[ref='saveButton']").hide();
          }
        }
      );
      return;
    } else {
      $("[ref='saveButton']").show();
    }
    return;
  };

  submitForm = (data) => {
    // console.log("submitForm called", data);

    // $(".component-edit-container .card-body .component-preview").prepend("ss");

    // var isPremium = this.props.is_premium_facility;
    // const customComponents = this.props.custom_components;
    // const checkFormKeys = () => {
    //   if (!isPremium) {
    //     data.components.map((comp) => {
    //       if (customComponents.includes(comp.key)) {
    //         let a = data.components.pop(comp);
    //         console.log(a);
    //       }
    //     });
    //   }
    // };

    // checkFormKeys();

    var jsonElement = document.getElementById("json");
    jsonElement.innerHTML = "";
    jsonElement.appendChild(
      document.createTextNode(JSON.stringify(data, null, 4))
    );
  };

  render() {
    var form_json_data = JSON.parse(this.props.form_data);
    var layout_hint =
      "The layout functionality is meant to create the different columns within each row. We suggest you do this step last when created any note.";
    var component_hint =
      "Drag and drop any component onto your note. Each component will have different options to help you customize the note.";
    var widget_hint =
      "Widgets are a unique function of our software that you can add to any note. Widgets allow the notes to incorporate certain up-to-date prepopulated data found within various sections of the software. For example, the Medication List widget will display all the client’s active medications within your note automatically. There are several widgets that allow you to prepopulate the data and still change the prepopulated data concurrently. For example, the Psychiatric Diagnosis widget will prepopulate all established psych diagnosis for the client. Furthermore, you can change, add, and edit the psych diagnosis from within this widget. This allows for the user to refrain from having to leave their note to change data found elsewhere within the software. <i> We strongly encourage the use of the Signature Widget for all notes to allow for users to sign the note.</i> <br/> <br/> We do not recommend adding widgets to client forms except for the Signature widget. This is to prevent the client from changing data about themselves, such as their psych diagnosis.";

    var fa_plus_color = "color: #2e4a7a;";

    var custom_builder = {
      basic: false,
      advanced: false,
      data: false,
      premium: false,
      layout: false,
      customLayout: {
        title:
          'Layout <span data-hint="' +
          layout_hint +
          '"></span> <i class="fa fa-plus" style="' +
          fa_plus_color +
          '"></i>',
        weight: 1,
        default: true,
        components: {
          // sectionCustomComp: true
          columns: {
            title: "Sections",
            key: "columns",
            icon: "columns",
            schema: {
              type: "columns",
              key: "columns",
            },
          },
        },
      },
      customComponents: {
        title:
          'Components <span data-hint="' +
          component_hint +
          '"></span> <i class="fa fa-plus" style="' +
          fa_plus_color +
          '"></i>',
        default: true,
        weight: 2,
        components: {
          headingCustomComp: true,
          hiddenFieldCustomComp: true,
          textFieldCustomComp: true,
          textAreaCustomComp: true,
          dividerCustomComp: true,
          spacerCustomComp: true,
          explanatoryTextCustomComp: true,
          // selectboxes: true,
          checkBoxesCustomComp: true,
          radioButtonsCustomComp: true,
          // checkmatrix: true,
          tableCustomComp: true,
          emailCustomComp: true,
          phoneCustomComp: true,
          // sectionCustomComp: true,
          // columns: {
          //   title: 'Sections',
          //   key: 'columns',
          //   icon: 'columns',
          //   schema: {
          //     label: 'First Name',
          //     type: 'columns',
          //     key: 'columns',
          //     input: true
          //   }
          // }
        },
      },
      customWidget: {
        title:
          'Widget <span data-hint="' +
          widget_hint +
          '"></span> <i class="fa fa-plus" style="' +
          fa_plus_color +
          '"></i>',
        weight: 3,
        default: true,
        components: {
          signatureComp: true,
          psychDiagnosisComp: true,
          medicalDiagnosisComp: true,
          medicationListComp: true,
          procedureCodeComp: true,
          demographicComp: true,
          datetimeComp: true,
          vitalSignsComp: true,
          drugAllergiesComp: true,
          nonDrugAllergiesComp: true,
          goalsComp: true,
          problemsComp: true,
          //proceduresComp: true,
          itemsProceduresComp: true,
          immunizationComp: true,
          udiComp: true,
          // sectionCustomComp: true,
          // bloodPressureComp: true,
          // medicationComp: true,
          demographicComp: true,
        },
      },
      premiumWidget: {
        title:
          'Premium Widgets <span data-hint="' +
          widget_hint +
          '"></span> <i class="fa fa-plus" style="' +
          fa_plus_color +
          '"></i>',
        weight: 3,
        default: true,
        components: {
          presentIllnessComp: true,
          assessmentsAndPlansComp: true,
          mentalStatusExamComp: true,
          pastInpatientComp: true,
          pastOutpatientComp: true,
          psychROSComp: true,
        },
      },
      assessmentToolWidget: {
        title:
          'Assessment Tool <span data-hint="' +
          widget_hint +
          '"></span> <i class="fa fa-plus" style="' +
          fa_plus_color +
          '"></i>',
        weight: 3,
        default: true,
        components: {
          assessmentToolComp: true,
          assessmentToolTabularComp: true,
          weightedScoreComp: true,
          weightedCheckboxesComp: true
          
        },
      },
    };

    var edit_form_builder = {
      columns: [
        {
          key: "display",
          label: "Settings",
          ignore: false,
          components: [
            { key: "label", ignore: true },
            { key: "autoAdjust", ignore: true },
            { key: "hideOnChildrenHidden", ignore: true },
            { key: "customClass", ignore: true },
            { key: "hidden", ignore: true },
            { key: "hideLabel", ignore: true },
            { key: "modalEdit", ignore: true },
            {
              weight: 1,
              type: "datagrid",
              input: true,
              key: "columns",
              label: "Create Columns",
              addAnother: "Add Column",
              reorder: true,
              components: [
                {
                  type: "hidden",
                  key: "components",
                  defaultValue: [],
                },
                {
                  key: "size",
                  ignore: true,
                },
                {
                  type: "number",
                  key: "width",
                  defaultValue: 6,
                  label: "Width",
                },
                {
                  key: "offset",
                  ignore: true,
                },
                {
                  key: "push",
                  ignore: true,
                },
                {
                  key: "pull",
                  ignore: true,
                },
              ],
            },
          ],
        },
        {
          key: "data",
          ignore: true,
        },
        {
          key: "validation",
          ignore: true,
        },
        {
          key: "api",
          ignore: true,
        },
        {
          key: "conditional",
          ignore: true,
        },
        {
          key: "logic",
          ignore: true,
        },
        {
          key: "layout",
          ignore: true,
        },
      ],
    };

    return (
      <div className="App">
        <FormBuilder
          form={form_json_data}
          onChange={(schema) => this.submitForm(schema)}
          options={{
            builder: custom_builder,
            noDefaultSubmitButton: true,
            editForm: edit_form_builder,
          }}
          onEditComponent={(comp) => this.displyPremium(comp)}
        />
      </div>
    );
  }
}

export default CustomFormBuilder;
